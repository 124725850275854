angular.module('ufcw')

.constant('EnvConfig', {
  "URL_API"			: "https://api-agenda.flexclub.com.uy/",
  "URL_IMAGES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/flexclub/",
  "URL_FILES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/flexclub/",
  "COMPANY_ID"  : "1",
  "SIGNIN_ENABLED":true,
  "RESERVATION_ENABLED":true,
  "footer_app_name":"FlexClub",
  "footer_email":"info@flexclub.com.uy",
  "footer_facebook":"https://www.facebook.com/FlexClubUruguay",
  "footer_twitter":"https://twitter.com/FlexClubUy",
  "footer_instagram":"https://www.instagram.com/flexcluburuguay/",
  "footer_youtube":"",
  "show_aviso_covid":false,
  "show_reglas":false,
  "signup_nrosocio":false,
  "signup_dob":false,
  "projectname":"flex",
  "show_payment_expiration": false,
  "show_carnet_expiration": true
});
